import CryptoJS from 'crypto-js'

var optionsRijndael = {
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7,
}

const getKeyAES = (paramKey = null) => {
  paramKey = paramKey
  let pwdBytes = CryptoJS.enc.Utf8.parse(paramKey)
  return pwdBytes
}

export const encryptAES = (text, paramKey = null) => {
  optionsRijndael.iv = getKeyAES(paramKey)
  var aes = CryptoJS.algo.AES.createEncryptor(
    getKeyAES(paramKey),
    optionsRijndael
  )
  var encrypted = aes.finalize(CryptoJS.enc.Utf8.parse(text))
  return CryptoJS.enc.Base64.stringify(encrypted)
}

export const decryptAES = (TeksSingDiEnkrip, paramKey = null) => {
  try {
    optionsRijndael.iv = getKeyAES(paramKey)
    var aes = CryptoJS.algo.AES.createDecryptor(
      getKeyAES(paramKey),
      optionsRijndael
    )
    let dataBase64Enkrip = CryptoJS.enc.Base64.parse(TeksSingDiEnkrip)
    var decrypted = aes.finalize(dataBase64Enkrip)
    return CryptoJS.enc.Utf8.stringify(decrypted)
  } catch (err) {
    console.log('error dec = ' + err.message)
  }
}
