export const panelCondition = {
  panel: [
    {
      panelName: 'Jaminan New',
      panelId: 'PA10002_G002',
      listField: [
        {
          id: 'PA10002_G002_003',
          label: 'Nomor KTP',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '',
          rule: '',
          isReadOnly: '1',
          width: '6',
          isMultiple: '',
          fieldSave: '1',
          path: '',
          hide: '',
          value: '3280772382737292',
          reference: {
            parent: [],
            child: [],
          },
        },
        {
          id: 'PA10002_G002_003_1',
          label: 'ID Fasilitas',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '',
          rule: '',
          isReadOnly: '1',
          width: '6',
          isMultiple: '',
          fieldSave: '1',
          path: '',
          hide: '',
          value: '022311002023',
          reference: {
            parent: [],
            child: [],
          },
        },
        {
          id: 'PA10002_G002_004',
          label: 'Data Jaminan',
          type: 'grid',
          maxLength: '',
          isMandatory: '',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          fieldSave: '1',
          path: '',
          hide: '',
          reference: {
            parent: ['PA10002_G002_003'],
            child: [],
          },
        },
      ],
    },
    {
      panelName: 'Jaminan',
      panelId: 'PA10002_G002',
      listField: [
        {
          id: 'PA10002_G002_003',
          label: 'Nomor KTP',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '',
          rule: '',
          isReadOnly: '1',
          width: '6',
          isMultiple: '',
          fieldSave: '1',
          path: '',
          hide: '',
          value: '3280772382737292',
          reference: {
            parent: [],
            child: [],
          },
        },
        {
          id: 'PA10002_G002_003_1',
          label: 'ID Fasilitas',
          type: 'textbox',
          maxLength: '100',
          isMandatory: '',
          rule: '',
          isReadOnly: '1',
          width: '6',
          isMultiple: '',
          fieldSave: '1',
          path: '',
          hide: '',
          value: '022311002023',
          reference: {
            parent: [],
            child: [],
          },
        },
        {
          id: 'PA10002_G002_004',
          label: 'Data Jaminan',
          type: 'grid',
          maxLength: '',
          isMandatory: '',
          rule: '',
          isReadOnly: '0',
          width: '6',
          isMultiple: '',
          fieldSave: '1',
          path: '',
          hide: '',
          reference: {
            parent: ['PA10002_G002_003'],
            child: [],
          },
        },
      ],
      action: [
        {
          actionId: 'PA10001_ACT001',
          label: 'Kembali',
          type: 'button',
          icon: 'fal fa-floppy-disk',
          className: 'btn btn-sm btn-warning',
          dataTarget: '',
          flagType: 'save',
          flagAction: 'SaveByNik',
          isRedirect: '0',
          needConfirm: '0',
          dataBackdrop: null,
          url: {
            param: ['PA10001_001_001'],
            path: '',
          },
        },
      ],
    },
  ],
}
