// node handle style
// input style
export const inputHandleStyle = {
    width: 8,
    height: 8,
    backgroundColor: 'gray',
}

// output style
export const outputHandleStyle = {
    width: 8,
    height: 8
}